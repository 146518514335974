import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { InputBase, Select, Typography, TextField } from "@material-ui/core";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 1,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "white",
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    padding: "5px 8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ArrowForwardIosOutlinedIcon fontSize="small" />}
        >
          <RadioButtonUncheckedOutlinedIcon
            fontSize="small"
            style={{ marginRight: 10 }}
          />
          <Typography>Open Link</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            inputMode={"url"}
            fullWidth
            size="small"
            placeholder="Enter URL"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={<ArrowForwardIosOutlinedIcon fontSize="small" />}
        >
          <RadioButtonUncheckedOutlinedIcon
            fontSize="small"
            style={{ marginRight: 10 }}
          />
          <Typography>Approve/Reject</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={<ArrowForwardIosOutlinedIcon fontSize="small" />}
        >
          <RadioButtonUncheckedOutlinedIcon
            fontSize="small"
            style={{ marginRight: 10 }}
          />
          <Typography>Navigate to Screen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            // value={"nigeria"}
            fullWidth
            // onChange={handleChange}
            input={<SelectInput />}
          >
            {/* {currencies.map((currency) => (
              <MenuItem key={currency.key} value={currency.key}>
                {currency.name}
              </MenuItem>
            ))} */}
          </Select>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
