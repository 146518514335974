import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { InputBase } from "@material-ui/core";

import Content from "./components/ActionContent";

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  inline: {
    display: "inline-flex",
  },
  percent: {
    padding: "2px 10px 5px",
    border: "1px solid #ABB3BF",
    borderLeft: "none",
    borderRadius: "0 3px 3px 0",
  },
  color: {
    marginTop: 3,
  },
}));

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    padding: "5px 8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

function ActionPane(props) {
  const classes = useStyles();
  //const colorCall = props.colorCallback;

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Action Button
      </Typography>
      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Actions
        </Typography>
      </div>
      <Content />
      <Divider />
    </div>
  );
}

export default ActionPane;
