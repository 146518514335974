import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    maxWidth: 500,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  screenTitle: {
    marginTop: 10,
    marginBottom: 30,
  },
  widen: {
    width: "50%",
  },
  asbForm: {
    width: "100%",
  },
  enScheduleBar: {
    flexDirection: "row",
  },
  activitySectionMargins: {
    marginTop: 30,
  },
  customButton: {
    // backgroundColor: "#dd4400",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  customButtonLabel: {
    textTransform: "capitalize",
    textDecoration: "none",
    fontSize: 12,
    fontWeight: 300,
    // lineHeight: 1,
  },
  formTextField: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 16,
    display: "flex",
    color: "#091540",
    backgroundColor: "#FFFFFF",
  },
  inputField: {
    height: "1.8rem",
    fontSize: "14px",
  },
  eye: {
    cursor: "pointer",
    paddingTop: "5px",
    paddingRight: "10px",
  },
  margin20: {
    padding: 20,
  },
  topMargin20: {
    marginTop: 20,
  },
  topMargin30: {
    marginTop: 30,
  },
  paddingLeft80: {
    padding: 20,
    // paddingLeft: 80,
    // width: "70%"
  },
  bottomMargin20: {
    marginBottom: 3,
  },
  noLeftMargin: {
    marginLeft: 0,
    width: 300,
  },
  floatRight: {
    justifyContent: "flex-end",
  },
  formLabel: {
    marginBottom: 13,
    fontWeight: 400,
  },
  asbSearchBar: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  noLineLink: {
    textDecoration: "none",
  },
  dataLabel: {
    color: "#999999",
  },
  inputData: {
    marginTop: 10,
    fontWeight: "bold",
  },
}));

export default useStyles;
