import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography,Grid } from "@material-ui/core";
import LabelControl from "../LabelControls";
import PlainToggleSwitch from "../PlainToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import UseStaticData from '../UseStaticData'
import ChipSelect from "../ChipSelector";
import TextInputStyle from "../TextInputStyle";
import RequiredEditable from "../RequiredEditable";
import Switch from '../PlainToggleSwitch';


const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();
  
  const {fileTooltipText} = props;

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
       Quantity                               
      </Typography>
      <Divider />
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Style
        </Typography>
        <div style={{ display: "inline-flex" }}>
          <InputText
            variant="outlined"
            size="small"
            placeholder=""
            defaultValue={""}
            style={{ width: "20%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </div>
       
      </div>
      <Divider />
      <div className={classes.section}>
        <LabelControl />
      </div>
      <Divider />
      <div  className={classes.section}>
        <div>
          <Typography gutterBottom className={classes.sectionTitle}>
            Preferences
          </Typography>
          <div style={{ display: "inline-flex" }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionLabel}>
                Minimum
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="1"
                defaultValue={0}
                style={{ width: "30%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
                Maximum
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="1"
                defaultValue={100}
                style={{ width: "30%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
          </Grid>
        </Grid>
        </div>   
        <div style={{ display: "inline-flex" }}>
        <Typography gutterBottom className={classes.sectionLabel}>
                Steps
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="1"
                defaultValue={1}
                style={{ width: "30%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
        </div>   

         
         
          </div>
          <Divider />
        <div>
        <div style={{ display: "inline-flex" }}>
        <Typography gutterBottom className={classes.sectionLabel}>
         Required
        </Typography>
        <Switch />
        </div>
      </div>
      <div>
        <div style={{ display: "inline-flex" }}>
        <Typography gutterBottom className={classes.sectionLabel}>
          Not Editable
        </Typography>
        <Switch />
        </div>
      </div>
      <div>
        <div style={{ display: "inline-flex" }}>
        <Typography gutterBottom className={classes.sectionLabel}>
          Place label by the side
        </Typography>
        <Switch />
        </div>
      </div>
      </div>
    </div>
  );
}




