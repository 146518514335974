import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Modal,
  Fade,
  Backdrop,
  IconButton,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import useStyles from "./style";

export default function NewUserModal({ closeModal, saveNewUserGroup }) {
  const classes = useStyles();
  const [newFormData, setNewFormData] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [emptyFieldCheck, setEmptyFieldCheck] = useState(true);

  const _updateForm = (orig, val) => {
    setNewFormData({
      ...newFormData,
      [orig]: val,
    });
  };

  const _handleNewUserForm = async () => {
    if (!newFormData.name || !newFormData.description) {
    } else {
      // saveNewUserGroup(newFormData);
      closeModal({ mode: "add", data: newFormData });
      setNewFormData({});
    }
  };
  useEffect(() => {
    if (newFormData.name && newFormData.description) {
      setEmptyFieldCheck(false);
    } else {
      setEmptyFieldCheck(true);
    }
  }, [newFormData]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.paper2}>
          <div>
            <div className={classes.modalHead}>
              <Typography
                variant="h5"
                component="h2"
                style={{ textTransform: "capitalize" }}
              >
                Create new user group
              </Typography>
              <IconButton
                aria-label="cancel"
                color="inherit"
                onClick={closeModal}
              >
                <Cancel fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.modalMain}>
              {/* {errMsg ? (
                <h3
                  style={{
                    color: "red",
                    fontWeight: "400",
                    margin: "0 0 10px",
                  }}
                >
                  {errMsg}
                </h3>
              ) : (
                ""
              )} */}
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h6" className={classes.formLabel}>
                    Name<span style={{ color: "red" }}>{"*"}</span>
                  </Typography>
                  <TextField
                    onChange={(e) => _updateForm("name", e.target.value)}
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter user group name"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.formLabel}>
                    Description<span style={{ color: "red" }}>{"*"}</span>
                  </Typography>
                  <TextField
                    onChange={(e) => _updateForm("description", e.target.value)}
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter group description"
                    multiline
                    size="small"
                  />
                </Grid>

                {/* <Grid item style={{ marginTop: 20 }}><Link to="/emailnotifications"><Button variant="contained" color="primary" className={classes.customButtonLabel}>Change</Button></Link></Grid> */}
              </Grid>
            </div>
            <div className={classes.modalBase}>
              <FormControl>
                <Button
                  onClick={closeModal}
                  variant="contained"
                  color="primary"
                  classes={{
                    root: classes.customButton,
                    label: classes.customButtonLabel,
                  }}
                >
                  Cancel
                </Button>
              </FormControl>
              <FormControl size="small" style={{ marginLeft: 10 }}>
                <Button
                  onClick={_handleNewUserForm}
                  variant="contained"
                  color="primary"
                  disabled={emptyFieldCheck}
                  classes={{
                    root: classes.customButton,
                    label: classes.customButtonLabel,
                  }}
                >
                  Save
                </Button>
              </FormControl>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
