import { composeQueryString } from "../../../../../common/utils/composeQueryString";
import { CustomAxios } from "../../../../../common/utils/CustomAxios";

//  get list of workflows
export const getScreenItemsAPI = async (options) => {
  const queryStr = composeQueryString(options);

  // alert(JSON.stringify(queryStr))
  if (!!queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/items${queryStr}`
  );

  return result.data;
};
