import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import LabelControl from "../LabelControls";
import { InputBase } from "@material-ui/core";
import Switch from "../PlainToggleSwitch";
import ColorPicker from "../../../../../../../common/components/ColorPicker";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    padding: "5px 8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  inline: {
    display: "inline-flex",
  },
  percent: {
    padding: "2px 10px 5px",
    border: "1px solid #ABB3BF",
    borderLeft: "none",
    borderRadius: "0 3px 3px 0",
  },
}));

const currencies = [
  {
    name: "Nigerian Naira (N)",
    key: "nigeria",
  },
];

export default function InvoiceTableSidebar(props) {
  const classes = useStyles();
  //const colorCall = props.colorCallback;

  /**const addHideInvoiceTableLabelProp = (layout) => {
    // dispatches actions to add todo
    props.addHideInvoiceTableLabelProp(layout);
  };**/
  const { updateProp, borderColor, backgroundColor, hideInvoicetableLabel } =
    props;
  const [data, setData] = useState({});

  function updateData(key, value) {
    setData({ ...data, [key]: value });
    updateProp(key, value);
  }

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Horizontal Bar Chart
      </Typography>
      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Style
        </Typography>
        <div>
          <div style={{ display: "inline-flex", marginTop: 5 }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Chart Lines Color
            </Typography>
            <ColorPicker
              identity="borderColor"
              selectedColor={borderColor}
              colorCallback={(v) => updateData("borderColor", v)}
            />
          </div>
        </div>
        <div>
          <div style={{ display: "inline-flex", marginTop: 5 }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Background Color
            </Typography>
            <ColorPicker
              identity="backgroundColor"
              selectedColor={backgroundColor}
              colorCallback={(v) => updateData("backgroundColor", v)}
            />
          </div>
        </div>
      </div>

      <Divider />

      <div className={classes.section}>
        <LabelControl
          labelToggleHide={(v) => updateData("hideInvoicetableLabel", v)}
        />
      </div>

      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>
        <div className={classes.inline}>
          <Typography gutterBottom className={classes.sectionLabel}>
            X-Axis Title
          </Typography>
          <InputText
            variant="outlined"
            size="small"
            placeholder="Enter axes title"
            defaultValue={""}
            style={{ width: "60%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </div>
        <div>
          <div className={classes.inline}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Y-Axis Title
            </Typography>
            <InputText
              variant="outlined"
              size="small"
              placeholder="Enter axes title"
              defaultValue={""}
              style={{ width: "60%" }}
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
                className: classes.input,
              }}
            />
          </div>
          <div className={classes.inline}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Include tooltip
            </Typography>
            <Switch />
          </div>
          <InputText
            variant="outlined"
            size="small"
            placeholder="Enter tooltip"
            defaultValue={""}
            style={{ width: "60%" }}
            row="3"
          />
        </div>
        <Divider />
        <div className={classes.inline}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Show data values
          </Typography>
          <Switch />
        </div>
        <div>
          <div className={classes.inline}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Show legends
            </Typography>
            <Switch />
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>
        <Typography gutterBottom className={classes.sectionTitle}>
          Tie Data
        </Typography>
        <InputText
          variant="outlined"
          size="small"
          placeholder="Enter tooltip"
          defaultValue={""}
          style={{ width: "100%" }}
          row="3"
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Represent
        </Typography>
        <InputText
          variant="outlined"
          size="small"
          placeholder="Enter tooltip"
          defaultValue={""}
          style={{ width: "100%" }}
          row="3"
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Against
        </Typography>
        <InputText
          variant="outlined"
          size="small"
          placeholder="Enter tooltip"
          defaultValue={""}
          style={{ width: "100%" }}
          row="3"
        />
      </div>
    </div>
  );
}
