import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { variableGroups } from "../../../utils/constants";

const ExecutionVariables = ({
  variables,
  selectedScreen,
  activeTask,
  setTaskInfo,
  properties,
  classes,
  joinExecutorVarsToVariables,
}) => {
  const [executorVariables, setExecutorVariables] = useState();
  // const { executorVariables } = properties;

  useEffect(() => {
    const execVars = (
      variables?.filter(
        (variable) =>
          variable?.group === variableGroups.EXECUTOR &&
          variable?.matching?.valueSourceId === activeTask?.taskId
      ) || []
    )?.map((variable) => ({
      id: variable?.matching?.valueSourceInput,
      name: variable?.name,
      dataType: variable?.dataType,
    }));
    setExecutorVariables(execVars);
  }, [variables, activeTask]);

  const handleChange = ({ e, dataType, name, type }) => {
    let newVars = [...executorVariables] || [];

    if (e.target.checked === true) {
      /* const variable = {
        id: v4(),
        name: name,
        parent: activeTask?.taskId,
        nodeType: activeTask?.type,
        dataType: dataType,
        group: variableGroups.EXECUTOR,
        matching: {
          valueSourceType: "task",
          valueSourceId: activeTask?.taskId,
          valueSourceInput: type,
        },
      }; */
      const newVar = {
        id: type,
        name,
        dataType,
      };
      newVars.push(newVar);
    } else {
      newVars = [...executorVariables].filter(
        (variable) => variable.id !== type
      );
    }

    joinExecutorVarsToVariables(newVars);
  };

  const toggleSaveExecutionVariables = (event) => {
    const { checked } = event.target;
    if (!checked) {
      joinExecutorVarsToVariables(executorVariables, null, true);
    }
    setTaskInfo(checked, "saveExecutorVariables");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "10px 0px 0px",
        }}
      >
        <FormControlLabel
          classes={{
            root: classes.switchLabel,
            label: classes.sectionTitle,
          }}
          control={
            <Switch
              key={!!activeTask?.properties?.saveExecutorVariables}
              defaultChecked={!!activeTask?.properties?.saveExecutorVariables}
              // onChange={(e) => setTaskInfo(e, "saveExecutorVariables")}
              onChange={toggleSaveExecutionVariables}
              name="checkedC"
              color="primary"
              size="small"
            />
          }
          label="Save execution variable"
          labelPlacement="start"
        />
      </div>

      {!!activeTask?.properties?.saveExecutorVariables ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                color="primary"
                checked={
                  !!executorVariables?.find(
                    (variable) => variable?.id === "name"
                  )
                }
                onChange={(e) =>
                  handleChange({
                    e,
                    name: `Exec. name - [${activeTask?.name || "..."}]`,
                    dataType: "text",
                    type: "name",
                  })
                }
              />
            }
            label={
              <Typography style={{ fontSize: 12 }}>{`Exec. name - [${
                activeTask?.name || "..."
              }]`}</Typography>
            }
            labelPlacement="end"
            className={"checkbox-executor"}
          />
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                color="primary"
                checked={
                  !!executorVariables?.find(
                    (variable) => variable?.id === "email"
                  )
                }
                onChange={(e) =>
                  handleChange({
                    e,
                    name: `Exec. email - [${activeTask?.name || "..."}]`,
                    dataType: "email",
                    type: "email",
                  })
                }
              />
            }
            label={
              <Typography style={{ fontSize: 12 }}>{`Exec. email - [${
                activeTask?.name || "..."
              }]`}</Typography>
            }
            labelPlacement="end"
            className={"checkbox-executor"}
          />
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                color="primary"
                checked={
                  !!executorVariables?.find(
                    (variable) => variable?.id === "date"
                  )
                }
                onChange={(e) =>
                  handleChange({
                    e,
                    name: `Exec. time - [${activeTask?.name || "..."}]`,
                    dataType: "date",
                    type: "date",
                  })
                }
              />
            }
            label={
              <Typography style={{ fontSize: 12 }}>{`Exec. time - [${
                activeTask?.name || "..."
              }]`}</Typography>
            }
            labelPlacement="end"
            className={["controlLabel", "checkbox-executor"]}
          />
        </FormGroup>
      ) : null}
    </>
  );
};

export default ExecutionVariables;
