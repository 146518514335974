import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Select, Typography } from "@material-ui/core";
import LabelControl from "../LabelControls";
import TooltipControl from "../ColoredToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import TextInputStyle from "../TextInputStyle";
import RequiredEditable from "../RequiredEditable";
import StyleOption from "../StyleOption";
import Switch from "../PlainToggleSwitch";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio'

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionTitle2: {
    fontSize: 10,
  },
  // sectionLabel: {
  //   color: "#999",
  //   fontSize: 10,
  //   marginRight: 12,
  //   marginTop: 5,
  // },
  sectionLabel: {
    color: "#999",
    lineHeight: 3,
    fontSize: 10,
    marginRight: 15,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  radioBtn: {
    fontSize: 10,
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('use static data');
  

  const inputType = [
    ["text", "Text"],
    ["email", "Email"],
    ["url", "URL"],
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
  };
 

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Dual Text
      </Typography>
      <Divider />
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Text Style
        </Typography> 
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Left Text
          </Typography>
          <StyleOption />
        </div>  
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Right Text
          </Typography>
          <StyleOption />
        </div> 
      </div>   
      <Divider />
      <div className={classes.section}>
        <LabelControl />
      </div>
      <Divider />
      <div className={classes.section}>     
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Required
          </Typography>
          <Switch />
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
      <Typography gutterBottom className={classes.sectionTitle}>
        Data Source
      </Typography>
      <Typography gutterBottom  className={classes.sectionTitle2}>
        Use Static Data
      </Typography>
      <div >
        <InputText
          variant="outlined"
          size="small"
          placeholder="Enter Left data"
          // defaultValue={"Enter"}
          style={{ width: "80%" }}
          inputProps={{
            min: 0,
            className: classes.input,
          }}
        />
      </div>
      </div>
      <div className={classes.section}>
        <InputText
          variant="outlined"
          size="small"
          placeholder="Enter Right data"
          // defaultValue={"Enter"}
          style={{ width: "80%" }}
          inputProps={{
            min: 0,
            className: classes.input,
          }}
        />
        </div>
    </div>
  );
}
