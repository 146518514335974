import React from "react";
import { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  TextField,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { errorToastify } from "../../utils/Toastify";
import { makeStyles } from "@material-ui/core/styles";
import aiIntelligence from "../../../../assets/images/ai-intelligence.jpg";
import { generateFormFieldsWithPrompt } from "../Mutation/Apps/AppsMutation";

const AIModal = ({
  setShowAiModal,
  showAiModal,
  page,
  appId,
  activeScreenId,
  reloadCanvas,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backdropFilter: "blur(1.7px)",
    },
    dialogSize: {
      width: 750,
      borderRadius: 10,
    },
  }));
  const classes = useStyles();
  const [textPrompt, setTextprompt] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRequestToEndpoint = async () => {
    if (page === "workflows") return;
    // endpoint - /items/create-form-from-text-input
    try {
      setIsSubmitting(true);

      const data = {
        textPrompt: textPrompt,
        appId: appId,
        screenId: activeScreenId,
      };

      const res = await generateFormFieldsWithPrompt(data);
      // const res = "SUCCESS";

      if (res === "SUCCESS") {
        //reload canvas and close the modal
        setIsSubmitting(false);
        reloadCanvas();
        setShowAiModal(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      errorToastify(
        "Sorry, An error occured. Please retry again in a short while"
      );
    }
  };

  return (
    <Dialog
      onClose={() => {
        setShowAiModal((prev) => !prev);
        setTextprompt("");
      }}
      aria-labelledby="ai-sugesstion-modal"
      open={showAiModal}
      classes={{
        root: classes.root,
        paper: classes.dialogSize,
      }}
    >
      <DialogTitle>
        AI prompt box
        <IconButton
          size="small"
          style={{ position: "absolute", top: 15, right: 20 }}
          onClick={() => setShowAiModal(false)}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 20px",
            }}
          >
            <img
              src={aiIntelligence}
              alt="ai-intelligence"
              style={{ width: "250px" }}
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 20, fontWeight: 600 }} gutterBottom>
              Describe your {page === "uieditor" ? "form" : "workflow process"}{" "}
              using simple terms
            </Typography>

            <Typography gutterBottom>
              Providing detailed descriptions yields better results. You can
              customize the generated form using the UI Editor.
            </Typography>
          </div>

          <div>
            <Typography gutterBottom>I want to create a ...</Typography>

            <TextField
              variant="outlined"
              size="medium"
              fullWidth
              multiline
              minRows={6}
              value={textPrompt || ""}
              placeholder="Please type a valid prompt"
              onChange={(e) => setTextprompt(e.target.value)}
              InputProps={{
                style: {
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#091540!important",
                  LineHeight: 15.6,
                },
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Typography>Example:</Typography>
            <Typography style={{ textAlign: "justify" }}>
              "Create a job application form with the following fields: Full
              Name (First Name, Last Name), Email Address, Phone Number, and
              Position Applied For. Ensure all required fields are validated,
              provide placeholders, and design the form for easy readability."
            </Typography>
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "30px",
            }}
          >
            <Button
              variant="contained"
              style={{
                borderRadius: "5px",
                backgroundColor: `${isSubmitting ? "#ffffff" : "#2457C1"}`,
                boxShadow: "0px 4px 10px 1px rgba(43, 45, 66, 0.25)",
                color: `${isSubmitting ? "#2457C1" : "#FFF"}`,
                fontSize: 12,
                fontWeight: 500,
                padding: "10px 35px",
                cursor: `${isSubmitting ? "text" : "pointer"}`,
                textTransform: "none",
                border: `${isSubmitting ? "1.5px solid #2457C1" : ""}`,
              }}
              disabled={!textPrompt || isSubmitting}
              onClick={() => handleRequestToEndpoint()}
            >
              {isSubmitting ? (
                <>
                  Generating
                  <div className="activity-loader"></div>
                </>
              ) : (
                "Generate"
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AIModal;
