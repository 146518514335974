import { composeQueryString } from "../../../../../common/utils/composeQueryString";
import { CustomAxios } from "../../../../../common/utils/CustomAxios";

//  get list of workflows
export const getScreensAPI = async (options) => {
  const queryStr = composeQueryString(options);
  if (queryStr === false) return { data: [] };

  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/screens${queryStr}`
  );

  return result.data;
};
