import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import AccountCircleIconOutlined from "@material-ui/icons/AccountCircleOutlined";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import useStyles from "./style";
import AssignUserModal from "./AssignUserModal";
import CustomPromptBox from "../../../../common/components/CustomPromptBox/CustomPromptBox";

const SingleUser = ({
  item,
  toggleRolePermissions,
  toggleRoleUsers,
  deleteMe,
}) => {
  const classes = useStyles();
  const [openAssignMembers, setOpenAssignMembers] = useState(false);
  const [groupData, setGroupData] = useState({});
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [fakeGroupMemebersCount, setFakeGroupMemebersCount] = React.useState(
    item?.users?.length || 0
  );
  const [deleteUserGroupModal, setDeleteUserGroupModal] = useState(false);

  const handleAssignMembers = () => {
    setOpenAssignMembers(true);
    setGroupData(item);
  };

  const handleRemoveGroup = async () => {
    // const conf = prompt('Are you sure? Type "delete" to confirm');
    // if (conf !== "delete") return;

    deleteMe();
  };

  const fakeDeltaMembersLength = (delta) => {
    setFakeGroupMemebersCount(fakeGroupMemebersCount + delta);
  };
  useEffect(() => {
    //console.log(item);
  }, []);

  return (
    <Grid item xs={4} sm={4} style={{ display: isDeleted ? "none" : "flex" }}>
      <div className={classes.moredetails}>
        <div
          style={{
            display: "flex",
            padding: "5px 20px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.5px solid rgba(238, 237, 242, 0.92)",
          }}
        >
          <Typography className={classes.heading5}>{item.name}</Typography>
          <div>
            <AccountCircleIconOutlined
              className={classes.accounticon}
              onClick={handleAssignMembers}
            />
            <DeleteOutlined
              className={classes.accounticon}
              style={{ marginLeft: 10 }}
              onClick={() => setDeleteUserGroupModal(true)}
            />
          </div>
        </div>
        <div
          style={{
            padding: "10px 20px",
            height: 50,
            overflow: "overlay",
            paddingBottom: 30,
          }}
        >
          <Typography style={{ color: "#999999", fontSize: 12 }}>
            {item.description}
          </Typography>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "solid 0.3px #f8f8f8",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          }}
        >
          {/* <Typography style={{color: "#999999", fontSize: 12, textAlign: "center", padding: "10px 0"}}>View Permissions</Typography>  */}
          <Typography className={classes.linkText}>
            <b>{fakeGroupMemebersCount || 0}</b>{" "}
            {fakeGroupMemebersCount === 1 ? "member" : "members"}
          </Typography>
        </div>
      </div>
      {openAssignMembers && (
        <AssignUserModal
          data={groupData}
          open={openAssignMembers}
          closeModal={() => setOpenAssignMembers(false)}
          fakeDeltaMembersLength={fakeDeltaMembersLength}
        />
      )}
      {deleteUserGroupModal && (
        <CustomPromptBox
          title="confirm deletion"
          actionText={
            "Kindly type “delete” in the text field below to continue this action. Note that you lose the user group and members assigned to it."
          }
          handleCloseDialog={setDeleteUserGroupModal}
          open={deleteUserGroupModal}
          confirmAction={() => handleRemoveGroup()}
        />
      )}
    </Grid>
  );
};

export default SingleUser;
