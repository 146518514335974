import { useEffect, useState } from "react";
import { Fade, Modal } from "@material-ui/core";
import useStyles from "./components/style";
import { ddDownload, getDownloadInfo } from "./utils";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const PreviewDownloadModal = ({ openModal, closeModal, pageUrl }) => {
  const classes = useStyles();
  const urlPath = pageUrl?.toString().split("/");
  const fileId = urlPath[urlPath?.length - 1];

  const [message, setMessage] = useState({
    type: "info",
    text: "Generating preview...",
  });

  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [mimeType, setMimeType] = useState(null);

  const fileTypes = {
    any: "",
    Image: "image/",
    Videp: "video/",
    Audio: "audio/",
    PDF: "application/pdf",
    TEXT:
      "application/msword" ||
      " application/vnd.ms-excel" ||
      "application/vnd.ms-powerpoint" ||
      "text/plain",
    Zip: "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
  };

  useEffect(() => {
    const handlePreviewDownload = async () => {
      const preDownload = await getDownloadInfo(fileId);

      if (!preDownload?._meta?.success) {
        setMessage({
          type: "error",
          text: "Invalid file request",
        });
        return;
      }

      const downloadedData = await ddDownload(
        fileId,
        pageUrl?.includes("/public/")
      );

      const { data: blobData } = downloadedData;

      if (downloadedData?.status === "error") {
        setMessage({
          type: "error",
          text: downloadedData?.response.statusText || "Error with File",
        });
      } else {
        const pdfBlob = new Blob([blobData], {
          type: preDownload?.data?.mimeType,
        });
        setMimeType(preDownload?.data?.mimeType);
        setMessage({
          type: "info",
          text: `${preDownload?.data?.name}`,
        });

        // preview the file
        const pdfBlobToUrl = URL.createObjectURL(pdfBlob); // create url from the blob data
        setPdfPreviewUrl(pdfBlobToUrl);
      }
    };

    !!pageUrl && handlePreviewDownload();
  }, [pageUrl]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={() => closeModal(false)}
      closeAfterTransition
    >
      <Fade in={true}>
        <div className={classes.paperPreview}>
          <>
            <p
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {message.text}{" "}
              {!mimeType && !pdfPreviewUrl && (
                <div className="activity-loader"></div>
              )}
            </p>

            {mimeType && pdfPreviewUrl && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => closeModal(false)}
              >
                <CloseIcon />
              </IconButton>
            )}

            {mimeType === fileTypes.PDF && pdfPreviewUrl && (
              <iframe
                src={pdfPreviewUrl}
                width="100%"
                height="600px"
                style={{ border: "none" }}
                title="PDF Preview"
              />
            )}

            {mimeType?.includes(fileTypes.Image) && pdfPreviewUrl && (
              <img
                src={pdfPreviewUrl}
                alt="Previewing"
                style={{
                  maxWidth: "100%",
                  maxHeight: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
          </>
        </div>
      </Fade>
    </Modal>
  );
};

export default PreviewDownloadModal;
