import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import Switch from "../PlainToggleSwitch";
import Select from "../Selector";
import LabelControl from "../LabelControls";
import TooltipControl from "../ColoredToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import ColorPicker from "../../../../../../../common/components/ColorPicker";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 11,
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 15,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();
  const {
    layoutStyle,
    colorStyle,
    hideLabel,
    labelName,
    labelNameTextSize,
    labelNameTextStyle,
    labelNameTextAlign,
    fileLabelColor,
    filePreferenceText,
    fileUploadFrom,
    hideToolTip,
    fileTooltipText,
    updateProp,
  } = props;

  function updateData(key, value) {
    updateProp(key, value);
  }
  const layout = [
    ["fill", "Filled"],
    ["outline", "Outlined"],
  ];

  const fileType = [
    ["any", "Any"],
    ["image", "Image"],
    ["audio", "Audio"],
    ["pdf", "PDF"],
    ["zip", "Zip"],
  ];

  const uploadFrom = [
    ["any", "File"],
    ["image", "Camera"],
    ["audio", "Both Camera & File"],
  ];

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Menu List
      </Typography>
      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Style
        </Typography>
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Layout
          </Typography>
          <Select
            items={layout}
            key="layout"
            itemType={"layout"}
            selectedValue={layoutStyle}
            selectorCall={(v) => updateData("layoutStyle", v)}
          />
          <div style={{ marginLeft: 15, marginTop: 3 }}>
            <ColorPicker
              identity="layoutColor"
              selectedColor={colorStyle}
              colorCallback={(v) => updateData("colorStyle", v)}
            />
          </div>
        </div>
      </div>

      <Divider />

      <div className={classes.section}>
        <LabelControl
          labelHide={hideLabel}
          labelToggleHide={(v) => updateData("hideLabel", v)}
          labelName={(v) => updateData("labelName", v)}
          selectedSize={(v) => updateData("labelNameTextSize", v)}
          selectedWeight={(v) => updateData("labelNameTextStyle", v)}
          selectedAlign={(v) => updateData("labelNameTextAlign", v)}
          textColorCallback={(v) => updateData("fileLabelColor", v)}
        />
      </div>

      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Text
          </Typography>
          <InputText
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => updateData("filePreferenceText", e.target.value)}
            placeholder="Click to upload file"
            value={filePreferenceText}
            InputProps={{
              className: classes.input,
            }}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Upload from
          </Typography>
          <Select
            items={uploadFrom}
            key="uploadFrom"
            itemType={"uploadFrom"}
            selectedUploadFrom={(v) => updateData("fileUploadFrom", v)}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography
            gutterBottom
            className={classes.sectionLabel}
            style={{ marginRight: 10 }}
          >
            Allow Multiple File Download
          </Typography>
          <Switch />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Number of files allowed
          </Typography>

          <InputText
            variant="outlined"
            size="small"
            placeholder="1"
            defaultValue={1}
            style={{ width: "30%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            File Type
          </Typography>

          <Select items={fileType} key="fileType" />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Maximum File Size
          </Typography>

          <InputText variant="outlined" size="small" style={{ width: "40%" }} />
        </div>

        <TooltipControl
          selectedText={fileTooltipText}
          value={!hideToolTip}
          toolTipToggle={(v) => updateData("hideToolTip", !v)}
          fileTooltipText={(v) => updateData("fileTooltipText", v)}
        />

        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Required
            </Typography>
            <Switch />
          </div>
        </div>
      </div>
    </div>
  );
}
