import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography, TextField, Grid } from "@material-ui/core";
import LabelControl from "../LabelControls";
import ColouredToggleSwitch from "../ColoredToggleSwitch";
import InputBase from "@material-ui/core/InputBase";
import ChipSelect from "../ChipSelector";
import TextInputStyle from "../TextInputStyle";
import RequiredEditable from "../RequiredEditable";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();

  const { fileTooltipText } = props;

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Number
      </Typography>
      <Divider />

      <div className={classes.section}>
        <TextInputStyle />
      </div>

      <Divider />

      <div className={classes.section}>
        <LabelControl />
      </div>

      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Preferences
        </Typography>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Placeholder
          </Typography>
          <InputText
            size="small"
            placeholder="Enter Placeholder text"
            defaultValue="Enter Placeholder text"
            inputProps={{
              className: classes.input,
            }}
          />
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Format
          </Typography>
          <ChipSelect
          //    items={fields}
          />
        </div>

        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Unit
            </Typography>
            <ChipSelect
            //    items={fields}
            />
          </div>
        </div>

        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Show thousand separators
          </Typography>
          <ColouredToggleSwitch />
        </div>
        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Limit Value
            </Typography>
            <ColouredToggleSwitch />
          </div>
        </div>
        <div style={{ display: "inline-flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div style={{ display: "inline-flex" }}>
                <Typography gutterBottom className={classes.sectionLabel}>
                  Minimum
                </Typography>
                <InputText
                  variant="outlined"
                  size="small"
                  placeholder="1"
                  defaultValue={1}
                  style={{ width: "30%" }}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "center" },
                    className: classes.input,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionLabel}>
                Maximum
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="1"
                defaultValue={10}
                style={{ width: "30%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Limit character no
            </Typography>
            <ColouredToggleSwitch />
          </div>
        </div>
        <div style={{ display: "inline-flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div style={{ display: "inline-flex" }}>
                <Typography gutterBottom className={classes.sectionLabel}>
                  Minimum
                </Typography>
                <InputText
                  variant="outlined"
                  size="small"
                  placeholder="1"
                  defaultValue={1}
                  style={{ width: "30%" }}
                  inputProps={{
                    min: 0,
                    style: { textAlign: "center" },
                    className: classes.input,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ display: "inline-flex" }}>
              <Typography gutterBottom className={classes.sectionLabel}>
                Maximum
              </Typography>
              <InputText
                variant="outlined"
                size="small"
                placeholder="1"
                defaultValue={10}
                style={{ width: "30%" }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: "inline-flex" }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Decimal Precision
          </Typography>
          <InputText
            variant="outlined"
            size="small"
            placeholder="0"
            defaultValue={0}
            style={{ width: "20%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </div>
        <div>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Typography gutterBottom className={classes.sectionLabel}>
              Include tooltip
            </Typography>
            <ColouredToggleSwitch />
          </div>
        </div>
        <TextField
          variant="outlined"
          size="small"
          //value={selectedText}
          onChange={(e) => fileTooltipText(e.target.value)}
          fullWidth
          multiline
          rows={2}
          className={classes.fullWidthText}
          placeholder="Enter tooltip text"
        />
      </div>
      <Divider />
      <div className={classes.section}>
        <RequiredEditable />
      </div>
    </div>
  );
}
